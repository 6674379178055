<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="标题:" prop="Title">
              <Input placeholder="标题" v-model="params.Title" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select
                transfer
                v-model="params.Status"
                clearable
                placeholder="状态"
              >
                <Option :value="10" :key="10"> 启用 </Option>
                <Option :value="20" :key="20"> 禁用 </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="编号:" prop="Code">
              <Input placeholder="编号" v-model="params.Code" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem prop="Version" label="版本号:">
              <Select clearable v-model="params.Version" placeholder="版本号">
                <Option
                  v-for="item in PositionVersionEnum"
                  :key="item.ID"
                  :value="item.CN"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            @click="handleAdd"
            v-if="$canAction(['AdvertPosition_Edit'])"
          >
            新增
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="memberTable"
      :columns="ListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="SortChange"
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="success"
          @click="editopenDrawer(row, true)"
        >
          编辑
        </Button>
        <Button
          class="ml2"
          size="small"
          type="error"
          @click="handleCancel(row)"
        >
          删除
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <EditDetailDrawer @on-save="loadList" ref="EditDetailDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import layout from "@/components/layout/index.vue";
import EditDetailDrawer from "./components/advertPosition-edit.vue";
export default {
  name: "AdvertPosition",
  data() {
    return {
      tableHeight: 0,
      tableLoading: false,
      params: {
        Name: "",
        Code: "",
        Status: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      ListOptions: [
        {
          title: "位置名称",
          key: "Name",
          align: "center",
          tooltip: true,
          minWidth: 150,
        },
        {
          title: "编号",
          key: "Code",
          align: "center",
          tooltip: true,
          minWidth: 70,
        },
        {
          title: "版本号",
          key: "Version",
          align: "center",
          tooltip: true,
          minWidth: 90,
        },
        {
          title: "高度",
          key: "Height",
          align: "center",
          tooltip: true,
          minWidth: 70,
        },
        {
          title: "宽度",
          key: "Width",
          align: "center",
          tooltip: true,
          minWidth: 70,
        },
        {
          title: "备注",
          key: "Remark",
          align: "center",
          tooltip: true,
          minWidth: 150,
        },
        {
          title: "状态",
          key: "StatusCN",
          align: "center",
          tooltip: true,
          minWidth: 70,
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.ListOptions.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: 105,
        maxWidth: 105,
        fixed: "right",
      });
    }
    this.loadList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.loadList();
      }
    });
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.params.Page = 1;
        self.loadList();
      }
    };
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.params.Page = 1;
        self.loadList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleAdd() {
      this.$refs.EditDetailDrawer.isShow = true;
      this.$refs.EditDetailDrawer.isAdd = true;
      this.$refs.EditDetailDrawer.id = -1;
      this.$refs.EditDetailDrawer.obj = {};
    },
    editopenDrawer(row, isEdit) {
      this.$refs.EditDetailDrawer.id = row.ID;
      this.$refs.EditDetailDrawer.isShow = true;
      this.$refs.EditDetailDrawer.isEdit = isEdit;
      this.$refs.EditDetailDrawer.obj = JSON.parse(JSON.stringify(row));
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    handleReset() {
      this.params.Status = "";
      this.params.Name = "";
      this.params.Code = "";
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    // 远程排序
    SortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    async loadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetAdvertPositionList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (err) {
        this.tableLoading = false;
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    handleCancel(row) {
      const self = this;
      this.$Modal.confirm({
        title: "删除确认",
        loading: true,
        content: "确定要删除该广告位吗?",
        okText: "删除",
        async onOk() {
          try {
            await api.DeleteAdvertPosition({ id: row.ID });
            const index = self.resObj.DataList.findIndex(
              (item) => item.ID === row.ID
            );
            if (index !== -1) {
              self.resObj.DataList[index].Status = -99;
              self.resObj.DataList[index].StatusCN = "已删除";
            }
            self.$Message.success("删除成功");
            self.$Modal.remove();
            setTimeout(function () {
              self.loadList();
            }, 800);
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
  },
  computed: {
    PositionVersionEnum() {
      return bizEnum.PositionVersionEnum;
    },
  },
  components: { layout, EditDetailDrawer },
};
</script>

<style lang="scss"></style>
