<template>
  <Modal v-model="isShow" title="广告位置管理" class-name="i-modal" width="800">
    <Form :model="obj" ref="levelForm" :rules="rules" :label-width="80">
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Name" label="位置名称">
            <Input type="text" v-model="obj.Name" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Code" label="编号">
            <Input type="text" v-model="obj.Code" />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Status" label="状态">
            <i-select
              transfer
              v-model="obj.Status"
              clearable
              placeholder="状态"
            >
              <i-option :value="10">启用</i-option>
              <i-option :value="20">禁用</i-option>
              <i-option :value="-99">删除</i-option>
            </i-select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Version" label="版本号:">
            <Select clearable v-model="obj.Version" placeholder="版本号">
              <Option
                v-for="item in PositionVersionEnum"
                :key="item.ID"
                :value="item.CN"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Height" label="高度">
            <Input type="text" v-model="obj.Height" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Width" label="宽度">
            <Input type="text" v-model="obj.Width" />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Remark" label="备注">
            <Input type="text" v-model="obj.Remark" />
          </FormItem>
        </Col>
      </Row>
    </Form>
    <template slot="footer">
      <Button type="default" @click="handleCancel">取消</Button>
      <Button type="primary" :loading="isUploading" @click="handleSave"
        >保存</Button
      >
    </template>
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "advertPosition-edit",
  created() {},
  data() {
    return {
      isShow: false,
      isUploading: false,
      SaveLoading: false,
      tableLoading: false,
      isAdd: false,
      isEdit: false,
      Type: 0,
      tableHeight: 0,
      obj: {},
      rules: {
        Name: [{ required: true, message: "名称不能为空" }],
      },
    };
  },
  watch: {},
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
  },
  methods: {
    handleCancel() {
      this.isShow = false;
    },
    async handleSave() {
      // TODO: 提交前数据验证待优化
      const _this = this;
      _this.$refs.levelForm.validate(async (valid) => {
        if (valid) {
          try {
            this.isUploading = true;
            await api.SaveAdvertPosition({
              entity: _this.obj,
            });
            this.$Message.success("保存成功");
            this.isUploading = false;
            this.isShow = false;
            setTimeout(function () {
              _this.$emit("on-save", this.obj);
            }, 800);
          } catch (err) {
            this.isUploading = false;
            this.$Notice.error({
              title: "错误",
              desc: err,
            });
          }
        }
      });
    },
  },
  computed: {
    PositionVersionEnum() {
      return bizEnum.PositionVersionEnum;
    },
  },
  components: {},
};
</script>

<style lang="scss"></style>
